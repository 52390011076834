import * as React from 'react'
import { BetterComponent } from '../better-component/better-component'
import { IRule, IRuleDefinition } from './interfaces/rule'
import './styles/rule-row.scss'
import { IRuleBuilderField, IRuleBuilderSelectField, RuleBuilderFieldType } from '../rule-builder/rule-builder'
import { baseOperators, RuleBuilderOperator } from '../rule-builder/operators'
import * as moment from 'moment-timezone'
import { BASE_DATE_FORMAT } from '../../constants'
import * as deepEqual from 'react-fast-compare'
import { FreeTextInput } from './inputs/free-text/free-text.input'
import { CustomFieldInput } from './inputs/custom-field/custom-field.input'
import { getFieldOperators, stripDomainIdPattern } from './utils'
import { BooleanInput } from './inputs/boolean/boolean.input'
import { DateInput } from './inputs/date/date.input'
import { NumberInput } from './inputs/number/number.input'
import { SelectInput } from './inputs/select/select.input'
import { CampaignInput } from './inputs/campaign/campaign.input'
import { PromptInput } from './inputs/prompt/prompt.input'
import { SubscriberPreferencesInput } from './inputs/subscriber-preferences/subscriber-preferences.input'
import { arrayContains } from '../../_utils/utils'
import { DevicePlacementInput } from './inputs/device-placement/device-placement.input'
import { CustomLocationInput } from './inputs/custom-location/custom-location.input'
import { CategoryAffinityInput } from './inputs/category-affinity/category-affinity.input'
import { BetterDateInput } from './inputs/date/better-date.input'
import { NoTranslate } from '../no-translate/no-translate'

interface IRuleRowProps {
    mode: 'display' | 'edit'
    rule: IRule
    builderFields: IRuleBuilderField[]
    onChange: (value: any, rule: IRule) => any
    autofocus?: boolean
}

interface IRuleRowState {}

export class RuleRowValue extends BetterComponent<IRuleRowProps, IRuleRowState> {
    public readonly defaultClassName = 'sw-v2-rb-rule-row-value'
    public ref: any
    public inputRef: any

    public constructor(props: IRuleRowProps) {
        super(props)
    }

    public render(): React.ReactNode {
        return (
            <div ref={(el) => (this.ref = el)} className={this.buildRootClassNames()}>
                {this.hasCustomRenderer ? (
                    this.field.render!(
                        this.props.mode,
                        this.field,
                        this.rule,
                        this.rule.operator,
                        this.handleChange,
                        (el) => (this.inputRef = el),
                    )
                ) : this.isDisplayMode ? (
                    !this.fieldTypeIsCustomType ? (
                        <div className="display-mode">
                            <NoTranslate>{this.displayValue}</NoTranslate>

                            {this.hasMeta && <span className="meta">{this.meta}</span>}
                        </div>
                    ) : (
                        // Field type Campaign is a special case render
                        <div className="display-mode">{this.resolveFieldElement()}</div>
                    )
                ) : (
                    <div className="edit-mode">{this.resolveFieldElement()}</div>
                )}
            </div>
        )
    }

    protected get fieldTypeIsCustomType(): boolean {
        return (
            !!this.field.render ||
            this.field.type === 'campaign' ||
            this.field.type === 'category-affinity' ||
            this.field.type === 'prompt' ||
            this.field.type === 'preferences' ||
            this.field.type === 'custom-location'
        )
    }

    protected get isDisplayMode(): boolean {
        return this.props.mode === 'display'
    }

    protected get rule(): IRuleDefinition {
        return this.props.rule.rule
    }

    protected get field(): IRuleBuilderField {
        const byFullProperty = (f: IRuleBuilderField) => f.property === this.rule.builderProperty
        const byDomainIdProperty = (f: IRuleBuilderField) =>
            stripDomainIdPattern(f.property) === this.rule.builderProperty

        return this.props.builderFields.find(byFullProperty) || this.props.builderFields.find(byDomainIdProperty)!
    }

    protected get fieldType(): RuleBuilderFieldType {
        let fieldType = this.field.type
        const opr: string = this.rule.operator

        if (this.field.conditionalType) {
            const isConditionalType: boolean =
                this.field.conditionalType.operators.map((o: RuleBuilderOperator) => o.value).indexOf(opr) !== -1

            if (isConditionalType) {
                fieldType = this.field.conditionalType.type
            }
        }

        return fieldType
    }

    protected get fieldTypeIsCampaign(): boolean {
        return this.fieldType === 'campaign'
    }

    protected get fieldTypeIsPreferences(): boolean {
        return this.fieldType === 'preferences'
    }

    protected get hasCustomRenderer(): boolean {
        return !!this.field.render
    }

    protected get operatorIsExistence(): boolean {
        return arrayContains([baseOperators.exists.value, baseOperators.not_exists.value], this.rule.operator)
    }

    protected get displayValue(): any {
        let value = this.rule.value
        if (this.rule.meta?.displayValue) {
            value = this.rule.meta?.displayValue
        }

        if (this.field.type === 'date' && this.isDate) {
            try {
                value = moment(value).format(BASE_DATE_FORMAT)
            } catch {}
        }

        if (this.operatorIsExistence) value = undefined

        return value !== null && value !== undefined ? value.toString() : undefined
    }

    protected get isDate(): boolean {
        return /^[\d]{4}-[\d]{2}-[\d]{2}$/.test(this.rule.value)
    }

    protected get hasMeta(): boolean {
        return !!this.rule.meta && typeof this.rule.meta === 'object' && Object.keys(this.rule.meta).length > 0
    }

    protected get meta(): any {
        let meta: React.ReactNode = ''

        if (this.hasMeta) {
            if ('qualifier' in this.rule.meta) {
                meta = this.rule.meta.qualifier
            }

            if ('range' in this.rule.meta) {
                if (this.field.type === 'date') {
                    if (this.field.property === 'profile.last_seen') return meta

                    meta = (
                        <>
                            <span className="using">using</span>
                            <span>{this.rule.meta === 'exact' ? 'exact dates' : 'relative dates'}</span>
                        </>
                    )
                }
            }
        }

        return meta
    }

    protected resolveFieldElement(): React.ReactNode {
        let field: React.ReactNode = ''

        switch (true) {
            case this.field.type === 'custom':
                field = (
                    <CustomFieldInput
                        ref={(el) => (this.inputRef = el)}
                        rule={this.props.rule}
                        onChange={this.handleChange}
                        fields={this.field.customFields!}
                        operators={getFieldOperators(this.field)}
                        typeaheadUrl={this.field.typeaheadUrl}
                        typeaheadTransform={this.field.typeaheadTransform}
                        suggestions={this.field.suggestions}
                        autofocus={this.props.autofocus}
                    />
                )
                break
            case this.field.type === 'campaign':
                field = (
                    <CampaignInput
                        ref={(el) => (this.inputRef = el)}
                        field={this.field}
                        rule={this.props.rule}
                        mode={this.props.mode}
                        onChange={this.handleChange}
                    />
                )
                break
            case this.field.type === 'category-affinity':
                field = (
                    <CategoryAffinityInput
                        ref={(el) => (this.inputRef = el)}
                        field={this.field}
                        rule={this.props.rule}
                        mode={this.props.mode}
                        onChange={this.handleChange}
                    />
                )
                break
            case this.field.type === 'prompt':
                field = (
                    <PromptInput
                        ref={(el) => (this.inputRef = el)}
                        field={this.field}
                        rule={this.props.rule}
                        mode={this.props.mode}
                        onChange={this.handleChange}
                    />
                )
                break
            case this.field.type === 'custom-location':
                field = (
                    <CustomLocationInput
                        ref={(el) => (this.inputRef = el)}
                        field={this.field}
                        rule={this.props.rule}
                        mode={this.props.mode}
                        onChange={this.handleChange}
                    />
                )
                break
            case this.field.type === 'preferences':
                field = (
                    <SubscriberPreferencesInput
                        ref={(el) => (this.inputRef = el)}
                        field={this.field}
                        rule={this.props.rule}
                        mode={this.props.mode}
                        onChange={this.handleChange}
                    />
                )
                break
            case this.field.type === 'device-placement':
                field = (
                    <DevicePlacementInput
                        ref={(el) => (this.inputRef = el)}
                        rule={this.props.rule}
                        onChange={this.handleChange}
                    />
                )
                break
            case this.field.type === 'select':
                field = (
                    <SelectInput
                        ref={(el) => (this.inputRef = el)}
                        field={this.field}
                        rule={this.props.rule}
                        onChange={this.handleChange}
                        options={this.field.options || []}
                        showSearch={this.field.enableOptionsSearch}
                        extra={(this.field as IRuleBuilderSelectField).extra}
                    />
                )
                break
            case this.field.type === 'date':
                if (this.field.property === 'profile.last_seen') {
                    field = <BetterDateInput rule={this.props.rule} onChange={this.handleChange} />
                    break
                }
                field = (
                    <DateInput ref={(el) => (this.inputRef = el)} rule={this.props.rule} onChange={this.handleChange} />
                )
                break
            case this.field.type === 'number':
                field = (
                    <NumberInput
                        ref={(el) => (this.inputRef = el)}
                        rule={this.props.rule}
                        onChange={this.handleChange}
                        metrics={
                            this.field.metric
                                ? Array.isArray(this.field.metric)
                                    ? this.field.metric
                                    : [this.field.metric]
                                : undefined
                        }
                        min={0}
                        autofocus={this.props.autofocus}
                    />
                )
                break
            case this.field.type === 'boolean':
                field = (
                    <BooleanInput
                        ref={(el) => (this.inputRef = el)}
                        rule={this.props.rule}
                        onChange={this.handleChange}
                    />
                )
                break
            case this.field.type === 'array' && Array.isArray(this.rule.value) && !this.field.typeaheadUrl:
                field = (
                    <SelectInput
                        ref={(el) => (this.inputRef = el)}
                        field={this.field}
                        rule={this.props.rule}
                        onChange={this.handleChange}
                        options={
                            this.field.options ||
                            this.field.suggestions?.map((s) => ({
                                name: s,
                                value: s,
                            })) ||
                            []
                        }
                        showSearch={this.field.enableOptionsSearch}
                        extra={{
                            ...(this.field as IRuleBuilderSelectField).extra,
                            mode: 'tags',
                            style: {
                                minWidth: '200px',
                            },
                        }}
                    />
                )
                break
            case this.field.type === 'string':
            default:
                field = (
                    <FreeTextInput
                        ref={(el) => (this.inputRef = el)}
                        rule={this.props.rule}
                        onChange={this.handleChange}
                        autofocus={this.props.autofocus}
                        typeaheadUrl={this.field.typeaheadUrl}
                        typeaheadTransform={this.field.typeaheadTransform}
                        suggestions={this.field.suggestions}
                    />
                )
                break
        }

        return field
    }

    protected handleChange = async (value: any): Promise<void> => {
        if (!deepEqual(value, this.rule.value)) this.props.onChange(value, this.props.rule)
    }

    protected buildClassName(className: string): string {
        return `${this.defaultClassName}-${className}`
    }

    protected buildRootClassNames(): string {
        const classNames: string[] = [this.defaultClassName]

        return classNames.join(' ')
    }
}
