import * as React from 'react'
import '@ant-design/compatible/assets/index.css'
import { ConfigurationContext } from './configuration-context'
import { CampaignEditableState, TriggerType } from '../enums'
import { RuleBuilderV2 } from '../../rule-builder-v2/rule-builder'
import { getClassNames } from '../../../_utils/classnames'
import { ComboSegmentSelection, Well } from '@pushly/aqe/lib/components'
import { ComboSegmentSelectionType } from '@pushly/aqe/lib/components/combo-segment-selection/combo-segment-selection'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Popover, Radio, Select, Skeleton, Switch } from 'antd'
import { extractCampaignTrigger } from '../../../_utils/campaigns'
import { stripUndefined } from '../../../_utils/strip-undefined'
import { CAMPAIGN_CRITERIA_FIELDS } from './constants'
import { SegmentDto } from '../../../dtos/segment'
import { CampaignAudienceEvaluationFrequency } from '../../../enums/campaign-audience-evaluation-frequency'
import { RadioChangeEvent } from 'antd/lib/radio'
import { AppState } from '../../../stores/app'
import { Container } from 'typescript-ioc/es5'
import { DomainService } from '../../../services'
import { DictionaryService } from '../../../services/dictionary'
import { buildLanguageCustomField } from '../../segment-builder/custom-fields/build-language.custom-field'
import { FEAT_CAMPAIGN_ITEM_GROUPS } from '../../../constants'
import '../style/audience-well.scss'
import { generateUID } from '../helpers/uid'
import SegmentBuilder from '../../segment-builder/segment-builder'
import { SegmentSource } from '../../../enums/segment-source.enum'
import { Drawer } from '../../drawer/drawer'
import { getEnabledDeliveryChannels } from '../../../_utils/domain'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { DeliveryChannelSelector } from '@pushly/aqe/lib/components/delivery-channel-selector/delivery-channel-selector'

interface IAudienceWell {
    submitConfiguration?: any
    getSegments: () => Promise<SegmentDto[]>
}

interface IState {
    initialSegmentsLoader?: Promise<SegmentDto[]>

    criteriaFieldsReady: boolean
    languageCodes: any[]
    languageCodesLoaded: boolean
    itemGroupsReady: boolean
    itemGroupsLoaded: boolean
    itemGroups: any[]

    optionsLoaded: boolean
    options: SegmentDto[]

    segmentSearchTerm?: string

    showSegmentDrawer: boolean
    drawerId?: string
    newSegmentType?: string
    newSegmentName?: string
}

export class AudienceWell extends React.Component<IAudienceWell, IState> {
    public static contextType = ConfigurationContext
    public context!: React.ContextType<typeof ConfigurationContext>

    public state: IState = {
        criteriaFieldsReady: false,
        languageCodes: [],
        languageCodesLoaded: false,
        itemGroupsReady: false,
        itemGroupsLoaded: false,
        itemGroups: [],
        showSegmentDrawer: false,
        optionsLoaded: false,
        options: [],
    }

    private readonly appState: AppState
    private readonly domainSvc: DomainService
    private readonly dictionarySvc: DictionaryService
    private segmentBuilderRef: any

    public constructor(props: IAudienceWell) {
        super(props)

        this.appState = Container.get(AppState)
        this.domainSvc = Container.get(DomainService)
        this.dictionarySvc = Container.get(DictionaryService)
    }

    public async componentDidMount() {
        await this.loadSegmentOptions()
        await this.loadCriteriaFields()
        await this.loadItemGroupField()

        const domainActiveChannels = getEnabledDeliveryChannels(this.context.domain, true)
        const domainHasMultipleChannels = domainActiveChannels.length > 1
        // we do not expose the delivery channel selector
        // if the domain does not have multiple channels enabled,
        // so set channels to the active channel
        if (!domainHasMultipleChannels) {
            this.handleChannelSelectionChange(domainActiveChannels)
        }

        // initialize the requested segment loader
        this.setState({
            initialSegmentsLoader: this.loadInitiallySelectedSegments(),
        })
    }

    public render() {
        const { mode, loading, domain, campaign, editableState, domainDefaultSegment, flags } = this.context
        const config = campaign.getConfiguration()
        const itemGroupIds = config.getItemGroupTargeting()?.getItemGroupIds() || []
        const domainHasItemGroups = this.state.itemGroups.length > 0
        const campaignItemGroupFlag = this.appState.flags.findActive(FEAT_CAMPAIGN_ITEM_GROUPS)
        const campaignItemGroupsEnabled =
            campaignItemGroupFlag && domain.flags?.includes(campaignItemGroupFlag?.getKey())

        const trigger = extractCampaignTrigger(campaign)
        const triggerConfig = trigger?.getConfiguration()
        const triggerType = triggerConfig?.type
        const isSubscribedTrigger = triggerType === TriggerType.SUBSCRIBED
        const triggerParams = triggerConfig?.params

        const audience = config.getAudience()
        const segmentIds = audience.getSegmentIds()
        const xSegmentIds = audience.getExcludedSegmentIds()
        const hasSegments = !!segmentIds || !!xSegmentIds

        const domainActiveChannels = getEnabledDeliveryChannels(domain, true)
        const domainHasMultipleChannels = domainActiveChannels.length > 1

        let audienceTargetType = 'all'
        if (hasSegments && !!segmentIds) {
            if (segmentIds.length === 0 || (!!domainDefaultSegment && segmentIds[0] !== domainDefaultSegment.id)) {
                audienceTargetType = 'segments'
            }
        }

        const audienceChangesDisabled = editableState === CampaignEditableState.COMPLETED
        const criteriaFields = [...CAMPAIGN_CRITERIA_FIELDS]
        if (this.state.languageCodesLoaded && this.state.languageCodes.length) {
            criteriaFields.push(buildLanguageCustomField('language', this.state.languageCodes))
        }

        return (
            <Well
                className={getClassNames(null, 'campaign-audience-well', 'nested', `mode-${mode}`, {
                    ['final-well']: !!this.props.submitConfiguration,
                })}
                title="Audience"
                hideFooter={!this.props.submitConfiguration}
                {...this.props.submitConfiguration}
            >
                <Skeleton
                    loading={loading}
                    active={true}
                    title={false}
                    paragraph={{
                        rows: 1,
                        width: '100%',
                    }}
                >
                    {domainHasMultipleChannels && (
                        <DeliveryChannelSelector
                            loading={loading}
                            wellMode="ghost"
                            wellNested={true}
                            type="multiple"
                            value={campaign.getChannels()}
                            onChange={this.handleChannelSelectionChange}
                            visibleChannels={domainActiveChannels}
                        />
                    )}

                    <Well mode="ghost" title="Target Audience" hideFooter={true}>
                        <Skeleton
                            loading={!domainDefaultSegment || !this.state.optionsLoaded}
                            active={true}
                            avatar={false}
                            paragraph={false}
                        >
                            <Select
                                className="campaign-trigger-editor-target-select"
                                size="small"
                                disabled={isSubscribedTrigger || audienceChangesDisabled}
                                value={isSubscribedTrigger ? 'all' : audienceTargetType}
                                onChange={this.handleAudienceTypeChange}
                            >
                                <Select.Option value="all">All Subscribers</Select.Option>
                                <Select.Option value="segments">Subscribers in Specific Segments</Select.Option>
                            </Select>

                            {!!domainDefaultSegment && (
                                <ComboSegmentSelection
                                    size="small"
                                    disabled={audienceChangesDisabled}
                                    value={{
                                        allSubscribers: false,
                                        segmentIds: audience.getSegmentIds(),
                                        excludedSegmentIds: audience.getExcludedSegmentIds(),
                                    }}
                                    hideIncluded={audienceTargetType !== 'segments'}
                                    hideExcluded={isSubscribedTrigger}
                                    onChange={(v) => {
                                        const workingModel = this.context.campaign.clone()
                                        const workingModelAudience = workingModel.getConfiguration().getAudience()
                                        workingModelAudience.setSegmentIds(v.segmentIds)
                                        workingModelAudience.setExcludedSegmentIds(v.excludedSegmentIds)

                                        if (v.segmentIds && v.segmentIds.length === 1) {
                                            const segmentId = v.segmentIds[0]
                                            const segment = this.state.options.find(
                                                (s) => s.id.toString() === segmentId.toString(),
                                            )

                                            if (segment?.iconUrl) {
                                                workingModelAudience.setDefaultIconUrl(segment.iconUrl)
                                            }
                                        } else {
                                            workingModelAudience.setDefaultIconUrl(undefined)
                                        }

                                        this.context.setCampaign(workingModel)
                                    }}
                                    options={this.state.options.map(this.extractOption)}
                                    additionalOptions={this.state.initialSegmentsLoader?.then((v) =>
                                        v.map(this.extractOption),
                                    )}
                                    notFoundContent={(type) => this.renderNewSegmentOption(type, true)}
                                    stickyOptionRenderer={(type) => (
                                        <Select.Option
                                            key="new"
                                            value="new"
                                            className={getClassNames(null, 'new-segment-option')}
                                        >
                                            {this.renderNewSegmentOption(type, false)}
                                        </Select.Option>
                                    )}
                                    onSearch={(v) => this.setState({ segmentSearchTerm: v })}
                                />
                            )}
                        </Skeleton>

                        <Drawer
                            className={getClassNames('combo-segment-select-new-segment-drawer')}
                            title="Create New Segment"
                            visible={this.state.showSegmentDrawer}
                            onSubmit={this.handleSegmentCreation}
                            onClose={this.closeDrawer}
                        >
                            <SegmentBuilder
                                key={this.state.drawerId ?? 'unknown'}
                                ref={(el) => (this.segmentBuilderRef = el)}
                                level="domain"
                                domainId={this.context.domain.id}
                                mode="drawer"
                                name={this.state.newSegmentName}
                                source={SegmentSource.STANDARD}
                            />
                        </Drawer>
                    </Well>

                    {isSubscribedTrigger && (
                        <Well
                            mode="ghost"
                            title="Entry Criteria"
                            hideFooter={true}
                            loading={!this.state.criteriaFieldsReady}
                        >
                            <div className="switch-row criteria-switch">
                                <div className="switch-left">
                                    <Switch
                                        size="small"
                                        disabled={audienceChangesDisabled}
                                        checked={flags.criteriaEnabled}
                                        onChange={this.handleCriteriaEnabledChange}
                                    />
                                </div>
                                <div className="switch-right">
                                    Restrict entry to this campaign based on subscriber attributes.
                                </div>
                            </div>

                            {flags.criteriaEnabled && this.state.criteriaFieldsReady && (
                                <>
                                    <RuleBuilderV2
                                        className="rb-original-skin"
                                        disabled={audienceChangesDisabled}
                                        value={triggerParams?.criteria || {}}
                                        onChange={this.handleCriteriaChange}
                                        fields={criteriaFields}
                                    />
                                </>
                            )}
                        </Well>
                    )}

                    {!isSubscribedTrigger && campaignItemGroupsEnabled && domainHasItemGroups && (
                        <Well
                            mode="ghost"
                            title={
                                <span>
                                    Target Items
                                    <Popover
                                        overlayClassName="eval-freq-info-popover"
                                        content={
                                            <>
                                                <p>
                                                    Setting a campaign’s item groups will restrict the items matched by
                                                    the campaign to only those that exist within the group. Contact your
                                                    account manager to create new item groups.
                                                </p>
                                            </>
                                        }
                                    >
                                        <QuestionCircleOutlined className="info-icon" />
                                    </Popover>
                                </span>
                            }
                            hideFooter={true}
                            loading={!this.state.itemGroupsReady}
                        >
                            <div className="switch-row criteria-switch">
                                <div className="switch-left">
                                    <Switch
                                        size="small"
                                        disabled={audienceChangesDisabled}
                                        checked={this.context.flags.itemGroupsEnabled}
                                        onChange={this.handleItemGroupsEnabledChange}
                                    />
                                </div>
                                <div className="switch-right">Restrict this Campaign to Specific Items</div>
                            </div>

                            {this.context.flags.itemGroupsEnabled && (
                                <Select
                                    className="campaign-item-group-select"
                                    size="small"
                                    mode="multiple"
                                    placeholder="Select at least one Item Group to target"
                                    value={[...itemGroupIds]}
                                    onChange={this.handleItemGroupSelectionChange}
                                >
                                    {this.state.itemGroups.map((group) => (
                                        <Select.Option key={group.id} value={group.id}>
                                            {group.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Well>
                    )}

                    <Well
                        mode="ghost"
                        title={
                            <span>
                                Evaluation Frequency
                                <Popover
                                    overlayClassName="eval-freq-info-popover"
                                    content={
                                        <>
                                            <p>
                                                <b>Campaign & Step Entry:</b> The subscriber will be checked against the
                                                audience on campaign entry and before entry in to each step. If the
                                                subscriber is no longer part of the audience they will be removed from
                                                the campaign.
                                            </p>
                                            <p>
                                                <b>Campaign Entry:</b> The subscriber will only be checked against the
                                                audience when they enter the campaign. They will continue executing
                                                steps of the campaign even if they lapse out of the audience.
                                            </p>
                                        </>
                                    }
                                >
                                    <QuestionCircleOutlined className="info-icon" />
                                </Popover>
                            </span>
                        }
                        hideFooter={true}
                    >
                        <Radio.Group
                            className="campaign-audience-eval-selection"
                            size="small"
                            buttonStyle="solid"
                            disabled={audienceChangesDisabled}
                            defaultValue="on_campaign_entry"
                            value={config.getAudience().getEvaluationFrequency()}
                            onChange={this.handleEvaluationFreqChange}
                        >
                            <Radio.Button value={CampaignAudienceEvaluationFrequency.STEP_ENTRY}>
                                Campaign & Step Entry
                            </Radio.Button>
                            <Radio.Button value={CampaignAudienceEvaluationFrequency.CAMPAIGN_ENTRY}>
                                Campaign Entry
                            </Radio.Button>
                        </Radio.Group>
                    </Well>
                </Skeleton>
            </Well>
        )
    }

    protected handleChannelSelectionChange = (channels: DeliveryChannel[]) => {
        const campaign = this.context.campaign.clone()
        campaign.setChannels(channels)

        this.context.setCampaign(campaign)
    }

    protected handleAudienceTypeChange = (type: any) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()
        const audience = config.getAudience()

        if (type === 'all') {
            audience.setSegmentIds([this.context.domainDefaultSegment!.id])
        } else {
            audience.setSegmentIds([])
        }

        this.context.setFlags({ criteriaEnabled: false })
        this.context.setCampaign(campaign)
    }

    protected handleEvaluationFreqChange = (ev: RadioChangeEvent) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()

        config.setAudience(
            stripUndefined({
                ...config.getAudience().serialize(),
                evaluation_frequency: ev.target.value,
            }),
        )

        this.context.setCampaign(campaign)
    }

    protected handleCriteriaEnabledChange = (criteriaEnabled: boolean) => {
        this.handleCriteriaChange(criteriaEnabled ? {} : undefined)
        this.context.setFlags({ criteriaEnabled })
    }

    protected handleCriteriaChange = (criteria: any) => {
        const campaign = this.context.campaign.clone()
        const trigger = extractCampaignTrigger(campaign)
        const triggerConfig = trigger.getConfiguration()

        trigger.setConfiguration({
            ...triggerConfig,
            params: stripUndefined({
                ...triggerConfig?.params,
                segments: undefined,
                criteria,
            }),
        })

        this.context.setCampaign(campaign)
    }

    protected handleItemGroupSelectionChange = (itemGroupIds: number[]) => {
        const campaign = this.context.campaign.clone()
        const config = campaign.getConfiguration()

        const itemGroupTargeting = {
            itemGroupIds,
        }
        config.setItemGroupTargeting(itemGroupTargeting)
        this.context.setCampaign(campaign)
    }

    protected handleItemGroupsEnabledChange = (itemGroupsEnabled: boolean) => {
        if (itemGroupsEnabled === false) {
            const campaign = this.context.campaign.clone()
            const config = campaign.getConfiguration()
            config.setItemGroupTargeting(undefined)
            this.context.setCampaign(campaign)
        }
        this.context.setFlags({ itemGroupsEnabled })
    }

    protected handleNewSegmentClick = (type: ComboSegmentSelectionType, name: string | undefined) => {
        this.setState({
            showSegmentDrawer: true,
            drawerId: generateUID(),
            newSegmentType: type,
            newSegmentName: name,
            segmentSearchTerm: undefined,
        })
    }

    protected handleSegmentCreation = async () => {
        const segment = await this.segmentBuilderRef?._submit()
        const type = this.state.newSegmentType

        if (segment?.id) {
            this.closeDrawer()

            const campaignModel = this.context.campaign.clone()
            const audienceValue = campaignModel.getConfiguration().getAudience()

            if (type === 'included') {
                const segmentIds = audienceValue.getSegmentIds() ?? []
                segmentIds.push(segment.id)
                audienceValue.setSegmentIds(segmentIds)
            } else {
                const segmentIds = audienceValue.getExcludedSegmentIds() ?? []
                segmentIds.push(segment.id)
                audienceValue.setExcludedSegmentIds(segmentIds)
            }

            campaignModel.getConfiguration().setAudience(audienceValue)
            this.context.setCampaign(campaignModel)

            this.loadSegmentOptions()
        }
    }

    protected closeDrawer = () => {
        this.setState({
            showSegmentDrawer: false,
            drawerId: undefined,
            newSegmentType: undefined,
            newSegmentName: undefined,
        })
    }

    protected async loadItemGroupField() {
        await this.loadItemGroups()

        this.setState({
            itemGroupsReady: true,
        })
    }

    protected async loadItemGroups() {
        const { domain } = this.context
        const state: any = { itemGroupsLoaded: false }
        this.setState(state)

        const { ok, data } = await this.domainSvc.fetchItemGroupsByDomainId(domain.id)

        if (ok) {
            this.setState({
                itemGroupsLoaded: true,
                itemGroups: data,
            })
        }
    }

    protected async loadCriteriaFields() {
        await this.loadLanguageCodes()

        this.setState({
            criteriaFieldsReady: true,
        })
    }

    protected async loadLanguageCodes() {
        const state: any = { languageCodesLoaded: false }
        this.setState(state)

        const { ok, data } = await this.dictionarySvc.fetchLanguageCodes()

        if (ok) {
            this.setState({
                languageCodesLoaded: true,
                languageCodes: data,
            })
        }
    }

    protected loadInitiallySelectedSegments = async (): Promise<SegmentDto[]> => {
        const { campaign, domain } = this.context
        const audience = campaign.getConfiguration().getAudience()
        const segmentIds = audience.getSegmentIds()
        const xSegmentIds = audience.getExcludedSegmentIds()
        const hasSegments = !!segmentIds || !!xSegmentIds

        let loadedSegments: SegmentDto[] = []

        if (hasSegments) {
            // batch each individual segment request
            const initialSegmentIds = [...(segmentIds ?? []), ...(xSegmentIds ?? [])]
            if (initialSegmentIds.length > 0) {
                const res = await this.domainSvc.fetchSegmentsByDomainId(domain.id, {
                    query: {
                        include_deleted: true,
                        id: initialSegmentIds.join(','),
                    },
                    showLoadingScreen: false,
                    cancellationKey: 'aw-resource-loader',
                })

                if (res.ok) {
                    loadedSegments = res.data
                }
            }
        }

        return loadedSegments
    }

    protected extractOption = (segment: SegmentDto) => ({
        value: segment.id,
        label: segment.name,
        title: segment.name,
    })

    protected renderNewSegmentOption = (type: ComboSegmentSelectionType, isEmptyMode: boolean) => {
        return (
            <div
                className={getClassNames(null, 'new-segment', {
                    [`type-${type}`]: true,
                    ['no-data']: isEmptyMode,
                })}
                onClick={() => this.handleNewSegmentClick(type, this.state.segmentSearchTerm)}
            >
                Create New Segment
            </div>
        )
    }

    protected loadSegmentOptions = async () => {
        this.setState({ optionsLoaded: false })

        const nextState: Pick<IState, 'optionsLoaded' | 'options'> = {
            optionsLoaded: true,
            options: this.state.options,
        }

        try {
            nextState.options = await this.props.getSegments()
        } catch (err) {
            console.warn('Unable to fetch segment options.', err)
        }

        this.setState(nextState)
    }
}
