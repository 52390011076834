import React from 'react'
import * as deepEqual from 'react-fast-compare'
import { Modal } from '@pushly/aqe/lib/components'
import { NotificationDto } from '../../features/notifications'
import { INotificationBuilderState } from './interfaces/notification-builder-state.interface'
import { NotificationTestConfigurationModel } from '../../models/notification/notification-test-configuration.model'
import { NotificationActionModel } from '../../models/notification/notification-action.model'
import { isFloat, isIntValue } from '../../_utils/math'
import { DomainDto } from '../../dtos/domain'
import { NotificationSource } from '../../enums/notification-source'
import { StatusType } from '../../enums/status-type'
import { getDefaultNotificationActions } from '../../_utils/domain'
import { isMacroMatch } from '../../_utils/macros'
import { Browser, Platform } from '@pushly/cuttlefish'
import { IValidationResponse } from '../../interfaces/validation-response'
import { arrayContains, numberWithCommas, simpleNotification } from '../../_utils/utils'
import { notification } from 'antd'
import { NotificationDeliveryType } from '../../enums/notification-delivery-type'
import { dateNow } from '../../_utils/datetime'
import { parseDateTimeInTz } from '../../_utils/moment'
import { PromiseableProp, resolvePromiseableProp } from '../../_utils/promiseable-prop'
import { NotificationVariantModel } from '../../models/notification/notification-variant.model'
import { NotificationExperienceModel } from '../../models/notification/notification-experience.model'
import { IosBadgeBehaviorAction, NotificationBuilderLevel, NotificationBuilderSubmitType } from './enums'
import { NotificationDeliveryWindow } from '../../enums/notification-delivery-window'
import { stripUndefined } from '../../_utils/strip-undefined'
import { NotificationService } from '../../services'
import { Moment } from 'moment'
import {
    BASE_TIME_FORMAT,
    BASE_TIME_FORMAT_WITHOUT_TZ,
    FEAT_AUTO_KW_DISCOVERY,
    FEAT_IMM_NOTIF_CONFIRM,
    FEAT_NOTIF_DUPLICATE_CHECKS,
    FEAT_RESTRICT_ALL_SUB_NOTIFS,
    FEAT_SCHED_NOTIF_CONFIRM,
} from '../../constants'
import { SegmentDto } from '../../dtos/segment'
import moment from 'moment-timezone'
import { NdfTestScheduleType } from '../notification-data-form/enums/ndf-test-schedule-type.enum'
import { NotificationDeliveryModel } from '../../models/notification/notification-delivery.model'
import { AccountDto } from '../../dtos/account.dto'
import { AppState } from '../../stores/app'
import FlagList from '../../structs/flags-list'
import { AbilityAction } from '../../enums/ability-action.enum'
import { PreviewState } from './elements/notification-builder-preview'
import { DeliveryChannel } from '@pushly/aqe/lib/enums/delivery-channels'
import { BadgeBehaviorModel } from '../../models/notification/notification-native-ios-content.model'
import { NoTranslate } from '../no-translate/no-translate'
import { IBetterSelectOption } from '../better-select/interfaces'
import { NotificationTemplateService } from '../../services/notification-template'
import PreviewConfig from '@pushly/cuttlefish/lib/models/PreviewConfig'

export const isAllFeatTest = (types?: string[]) => !!types && types.length === 2
export const isContentTest = (types?: string[]) =>
    isAllFeatTest(types) || (!!types && types.length === 1 && types[0] === 'content')
export const isDeliveryTest = (types?: string[]) =>
    isAllFeatTest(types) || (!!types && types.length === 1 && types[0] === 'delivery')
export const isSingleContent = (types?: string[]) => !isContentTest(types) && !isAllFeatTest(types)
export const isSingleDelivery = (types?: string[]) => !isDeliveryTest(types) && !isAllFeatTest(types)

export const getVariantTtlConfig = (
    ttlSource: DomainDto | AccountDto | undefined,
    model: NotificationExperienceModel,
) => {
    const globalDefaultTtlSeconds = 7 * 60 * 60 * 24
    const domainDefaultTtlSeconds = ttlSource?.defaultNotificationTtlSeconds
    const domainDefaultTtlMetric = ttlSource?.displayMeta?.default_notification_ttl_metric
    const ttlSeconds = model.getTtlSeconds() ?? domainDefaultTtlSeconds ?? globalDefaultTtlSeconds
    const ttlMetric = model.getTtlMetric() ?? domainDefaultTtlMetric ?? 'days'

    return { seconds: ttlSeconds, metric: ttlMetric }
}

export const parseLoadedVariant = (notif: NotificationDto): NotificationVariantModel => {
    const actions =
        notif.defaultTemplate.actions?.map(NotificationActionModel.build) ||
        notif.webTemplate.actions?.map(NotificationActionModel.build) ||
        notif.nativeAndroidTemplate.actions?.map(NotificationActionModel.build)

    const customActionsEnabled =
        notif.defaultTemplate.overrideDefaultActions ||
        notif.webTemplate?.displayMeta?.override_default_actions ||
        notif.nativeAndroidTemplate?.displayMeta?.override_default_actions

    return NotificationVariantModel.build({
        id: notif.id,
        audience: notif.audience,
        channels: notif.channels ?? notif.defaultTemplate.channels,
        content: {
            default: {
                title: notif.defaultTemplate.title,
                body: notif.defaultTemplate.body,
                keywords: notif.defaultTemplate.keywords,
                landingUrl: notif.defaultTemplate.landingUrl,
                altNativeUrl: notif.defaultTemplate.altNativeUrl,
                imageUrl: notif.defaultTemplate.imageUrl,
                iconUrl: notif.defaultTemplate.iconUrl,
                isUsingDomainDefaultIcon: notif.defaultTemplate.isUsingDomainDefaultIcon,
                badgeUrl: notif.defaultTemplate.badgeUrl,
                isUsingDomainDefaultBadge: notif.defaultTemplate.isUsingDomainDefaultBadge,
                actions,
                _custom_actions_enabled: customActionsEnabled,
                contentWebhookUrl: notif.defaultTemplate.contentWebhookUrl,
                overrideFallbackTemplate: notif.defaultTemplate.overrideFallbackTemplate,
                autoSuggestedFieldsResults: notif.defaultTemplate.autoSuggestedFieldsResults,
                experience_options: {
                    isSilent: notif.defaultTemplate.isSilent,
                    requireInteraction: notif.defaultTemplate.requireInteraction,
                    ttlSeconds: notif.deliverySpec.ttlSeconds,
                    ttlMetric: notif.deliverySpec.ttlMetric,
                },
                channels: notif.channels ?? notif.defaultTemplate.channels,
            },
            web: {
                title: notif.webTemplate.title,
                body: notif.webTemplate.body,
                keywords: notif.keywords,
                landingUrl: notif.webTemplate.landingUrl,
                imageUrl: notif.webTemplate.imageUrl,
                iconUrl: notif.webTemplate.iconUrl,
                actions: notif.webTemplate.actions?.map(NotificationActionModel.build),
                displayMeta: notif.webTemplate.displayMeta,
            },
            nativeIos: {
                title: notif.nativeIosTemplate.title,
                body: notif.nativeIosTemplate.body,
                imageUrl: notif.nativeIosTemplate.imageUrl,
                iconUrl: notif.nativeIosTemplate.iconUrl,
                landingUrl: notif.nativeIosTemplate.landingUrl,
                badgeBehavior: BadgeBehaviorModel.build(
                    typeof notif.nativeIosTemplate.badgeBehavior === 'string'
                        ? {
                              action: notif.nativeIosTemplate.badgeBehavior,
                              value: notif.nativeIosTemplate.badgeCount,
                          }
                        : notif.nativeIosTemplate.badgeBehavior,
                ),
                interruptionLevel: notif.nativeIosTemplate.interruptionLevel,
                relevanceScore: notif.nativeIosTemplate.relevanceScore,
                sound: notif.nativeIosTemplate.sound,
                subtitle: notif.nativeIosTemplate.subtitle,
                targetContentId: notif.nativeIosTemplate.targetContentId,
            },
            nativeAndroid: {
                title: notif.nativeAndroidTemplate.title,
                body: notif.nativeAndroidTemplate.body,
                imageUrl: notif.nativeAndroidTemplate.imageUrl,
                iconUrl: notif.nativeAndroidTemplate.iconUrl,
                landingUrl: notif.nativeAndroidTemplate.landingUrl,
                actions: notif.nativeAndroidTemplate.actions?.map(NotificationActionModel.build),
                displayMeta: notif.nativeAndroidTemplate.displayMeta,
            },
        },
        delivery: {
            type: notif.deliverySpec.type,
            window: notif.deliverySpec.window,
            sendDateUtc: notif.deliverySpec.sendDateUtc,
            timezone: notif.deliverySpec.timezone,
        },
        weight: notif.abTest?.weight,
    })
}

export const getDefaultElapsedSchedule = (): any => {
    return {
        type: NdfTestScheduleType.ELAPSED,
        elapsed_seconds: 60 * 60,
        $elapsed_metric: 'hour',
    }
}

export const getDefaultSpecificSchedule = (delivery: NotificationDeliveryModel, domain: DomainDto): any => {
    let currSendDateObj: Moment
    if (delivery?.getSendDate()) {
        const currSendDateTz = delivery!.getTimeZone() ?? domain.timezone
        currSendDateObj = parseDateTimeInTz(delivery!.getSendDate(), currSendDateTz)
    } else {
        currSendDateObj = moment()
    }

    return {
        type: NdfTestScheduleType.SPECIFIC,
        evaluation_date_utc: currSendDateObj.clone().add(1, 'day').toISOString(),
        evaluation_date_tz: delivery.getTimeZone() ?? domain.timezone,
    }
}

export const resetVariantDistributionMap = (container: INotificationBuilderState) => {
    if (!!container.test) {
        const type = container.test.getDistributionType()?.toLowerCase() ?? 'split'
        const mapTotal = type === 'split' ? 100 : 24
        const map = getNormalizedDist(container.variants.length, mapTotal)
        container.test!.setDistributionMap(map)
    }
}

export const addVariant = (v: NotificationVariantModel, container: INotificationBuilderState) => {
    container.variants.push(v.clone(false, true))

    resetVariantDistributionMap(container)

    return container
}

export const rmVariant = (idx: number, container: INotificationBuilderState) => {
    container.variants.splice(idx, 1)

    resetVariantDistributionMap(container)

    return container
}

export const removeAllOtherVariants = (variants: NotificationVariantModel[], container: INotificationBuilderState) => {
    const v = variants[container.selectedVariantIdx]

    container.variants = [v]
    container.selectedVariantIdx = 0

    return container
}

export const extractVariantPreview = (
    domain: DomainDto,
    variant: NotificationVariantModel,
    platform: Platform,
    state: PreviewState,
    chromeless: boolean = false,
) => {
    const content = variant.getContent()?.getDefaultContent()
    const iosContent = variant.getContent()?.getNativeIosContent()

    const defaultTitle = 'Sample Notification Title'
    const defaultBadge = '/assets/logos/pushly_icon_2019.png'
    const actions = content.getActions()?.map((a) => a.serialize()) ?? getDefaultNotificationActions(domain)

    // intercept ecomm macro image
    let imageUrl = content.getImageUrl()
    if (!!imageUrl && isMacroMatch(imageUrl, 'item.image')) {
        imageUrl = '/assets/ecomm-item-image-placeholder.png'
    }

    let iconUrl = content.getIconUrl()

    if ((platform === 'ios' || platform === 'android') && !iconUrl) {
        iconUrl = domain.defaultIconUrl
    }

    let badgeUrl = content.getBadgeUrl()
    if (content.getIsUsingDomainDefaultBadge() && !!domain.defaultBadgeUrl) {
        badgeUrl = badgeUrl ?? domain.defaultBadgeUrl
    }

    const body = content.getBody() ? <NoTranslate>{content.getBody()}</NoTranslate> : null
    const subtitle = iosContent.getSubtitle() ? <NoTranslate>{iosContent?.getSubtitle()}</NoTranslate> : null

    const nativeFcmConfig = domain.nativeFcmConfiguration as any

    const notificationShouldUseImageForIconWhenPresent = nativeFcmConfig.useImageForIconWhenPresent

    const previewConfig: any = {
        animated: false,
        browser: Browser.CHROME,
        platform,
        state,
        chromeless,
        appName: domain.displayName,
        notification: {
            title: <NoTranslate>{content.getTitle() || defaultTitle}</NoTranslate>,
            body,
            icon: iconUrl,
            useImageForIconWhenPresent: notificationShouldUseImageForIconWhenPresent,
            badgeColor: nativeFcmConfig.notificationAccentColor,
            image: imageUrl,
            badge: badgeUrl ?? defaultBadge,
            subtitle,
            actions:
                actions.length === 0
                    ? undefined
                    : actions
                          .sort((a, b) => (a.ordinal > b.ordinal ? 1 : -1))
                          .map((a) => ({
                              title: <NoTranslate>{a.label}</NoTranslate>,
                          })),
        },
    }

    if (!!domain) {
        previewConfig.notification.domain = domain.name
    }

    return previewConfig
}

export const validateTest = (builder: INotificationBuilderState): IValidationResponse => {
    let res: IValidationResponse = { ok: true, errors: [] }

    if (builder.test) {
        if (!builder.test.getName()?.trim()) {
            res.ok = false
            res.errors.push('Test name is required.')
        }

        if (builder.variants.length < 2) {
            res.ok = false
            res.errors.push('Tests must contain at least two variants.')
        }

        const distType = builder.test.getDistributionType()
        const evalSchedule = builder.test.getEvaluationSchedule()
        if (distType === 'sample' && evalSchedule && evalSchedule.getType() === NdfTestScheduleType.ELAPSED) {
            if (!isIntValue(evalSchedule.getElapsedSeconds())) {
                res.ok = false
                res.errors.push('Elapsed time after value is invalid.')
            }
        }
    }

    return res
}

export const validateAudience = (builder: INotificationBuilderState): IValidationResponse => {
    let res: IValidationResponse = { ok: true, errors: [] }

    const isOrgLevel = builder.level === NotificationBuilderLevel.ORG
    const isDomainLevel = builder.level === NotificationBuilderLevel.DOMAIN

    const variants = builder.variants
    const workingVariant = variants[builder.selectedVariantIdx]

    if (isOrgLevel) {
        const domainIds = workingVariant.getAudience().getDomainIds()
        const isAllSend = !domainIds && workingVariant.getAudience().getIsAll()

        if (!isAllSend && (!domainIds || domainIds.length === 0)) {
            res.ok = false
            res.errors.push('At least one audience domain is required.')
        }
    } else if (isDomainLevel) {
        const segmentIds = workingVariant.getAudience().getSegmentIds()
        const xSegmentIds = workingVariant.getAudience().getExcludedSegmentIds()

        if (!!segmentIds && segmentIds.length === 0) {
            res.ok = false
            res.errors.push('At least one audience segment is required.')
        }

        if (!!xSegmentIds && xSegmentIds.length === 0) {
            res.ok = false
            res.errors.push('At least one excluded audience segment is required, when exclusions are enabled.')
        }
    }

    return res
}

export const validateSelectedVariantContent = (builder: INotificationBuilderState): IValidationResponse => {
    let res: IValidationResponse = { ok: true, errors: [] }

    const isUsingTemplate = builder.usingTemplate
    const variants = builder.variants
    const workingVariant = variants[builder.selectedVariantIdx]
    const workingVariantContent = workingVariant.getContent().getDefaultContent()
    const landingUrl = workingVariantContent.getLandingUrl()?.trim()
    const title = workingVariantContent.getTitle()?.trim()
    const ttl = workingVariantContent.getExperienceOptions().getTtlSeconds()

    if (isUsingTemplate && !builder.selectedTemplate) {
        res.ok = false
        res.errors.push('A template must be selected if "Use saved template" is enabled.')
    }

    if (!landingUrl) {
        res.ok = false
        res.errors.push('A valid landing url is required.')
    }

    if (!title) {
        res.ok = false
        res.errors.push('Notification title is required.')
    }

    if (!ttl?.toString()?.trim()) {
        res.ok = false
        res.errors.push('Lifespan is required.')
    }

    return res
}

export const validateSelectedVariantDraftContent = (builder: INotificationBuilderState): IValidationResponse => {
    let res: IValidationResponse = { ok: true, errors: [] }

    const variants = builder.variants
    const workingVariant = variants[builder.selectedVariantIdx]
    const workingVariantContent = workingVariant.getContent().getDefaultContent()
    const title = workingVariantContent.getTitle()?.trim()

    if (!title) {
        res.ok = false
        res.errors.push('Notification title is required.')
    }

    return res
}

export const validateSelectedVariantDelivery = async (
    builder: INotificationBuilderState,
    domain: DomainDto,
): Promise<IValidationResponse> => {
    let res: IValidationResponse = { ok: true, errors: [] }

    const variants = builder.variants
    const workingVariant = variants[builder.selectedVariantIdx]
    const schedule = workingVariant.getDelivery()

    const type = schedule.getType()
    const isScheduled = type === NotificationDeliveryType.SCHEDULED
    const sendDate = schedule.getSendDate()

    if (isScheduled) {
        if (!sendDate?.toString()?.trim()) {
            res.ok = false
            res.errors.push('A valid delivery date/time is required.')
        } else {
            const now = dateNow().add(1, 'minute')
            const mmSendDate: Moment = parseDateTimeInTz(sendDate, schedule.getTimeZone()! ?? domain.timezone ?? 'utc')

            if (schedule.isUsingStzStrategy) {
                const stzSendDate: Moment = (mmSendDate as any).tz('utc', true).set({ second: 0, millisecond: 0 })

                const { data: validStz } = await NotificationService.fetchStzAvailability(stzSendDate.toISOString())
                if (!validStz) {
                    res.ok = false
                    res.errors.push('Requested delivery has passed in all subscriber time zones.')
                }
            } else if (mmSendDate <= now) {
                res.ok = false
                res.errors.push('Delivery date/time cannot be in the past.')
            }
        }
    }

    return res
}

export const renderValidationErrors = (validation: IValidationResponse) => {
    return validation.errors.map((err, idx) => (
        <span key={idx}>
            {err}
            <br />
        </span>
    ))
}

export const validateVariantSwitch = async (builder: INotificationBuilderState, domain: DomainDto) => {
    notification.destroy()

    const isConTest = builder.test?.getTestTypes()?.includes('content')
    const isDelTest = builder.test?.getTestTypes()?.includes('delivery')
    const contentValidation = !isConTest ? { ok: true } : validateSelectedVariantContent(builder)
    const deliveryValidation = !isDelTest ? { ok: true } : await validateSelectedVariantDelivery(builder, domain)
    const valid = (!isConTest || contentValidation.ok) && (!isDelTest || deliveryValidation.ok)

    if (!valid) {
        simpleNotification(
            'error',
            <div>
                {isConTest && !contentValidation.ok && (
                    <p>
                        <b>Content</b>
                        <br />
                        {renderValidationErrors(contentValidation as IValidationResponse)}
                    </p>
                )}
                {isDelTest && !deliveryValidation.ok && (
                    <p>
                        <b>Delivery</b>
                        <br />
                        {renderValidationErrors(deliveryValidation as IValidationResponse)}
                    </p>
                )}
            </div>,
        )
    }

    return valid
}

const checkforDuplicateNotifs = async (
    builder: INotificationBuilderState,
    domain: DomainDto,
    isDraft?: boolean,
): Promise<boolean> => {
    const isCreate = builder.mode === 'create'
    const landing_url = builder.variants[0].getContent().getDefaultContent().getLandingUrl()
    const audience = builder.variants[0].getAudience().getSegmentIds()
    const isAllSend =
        builder.variants[0].getAudience().getIsAll() ||
        audience?.some((seg) => {
            const segmentMatch = builder.availableSegments.findIndex((s) => s.id === seg)
            return segmentMatch >= 0 && builder.availableSegments[segmentMatch].isDefault
        })

    const { data: duplicate } = (await NotificationService.fetchNotificationDuplicates(domain.id, {
        landing_url,
        segments: audience?.join(','),
        limit: 1,
        rangeFrom: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        status: [StatusType.COMPLETED.name, StatusType.DELIVERING.name, StatusType.SCHEDULED.name].join(','),
        notification_id: isCreate ? undefined : builder.variants[0].getId(),
    })) as any

    if (duplicate?.id) {
        let duplicateSegments
        if (isAllSend) {
            duplicateSegments = duplicate.schedules[0]?.audience?.segments?.map((s) => <li key={s.id}>{s.name}</li>)
        } else {
            duplicateSegments = duplicate.schedules[0]?.audience?.segments
                ?.filter((s) => audience?.includes(s.id))
                .map((s) => <li key={s.id}>{s.name}</li>)
        }

        const notificationRoute = `${window.location.origin}/domains/${domain.id}/notifications/${duplicate.id}`

        return new Promise((res) => {
            Modal.confirm({
                title: 'Confirm Duplicate Notification Send',
                content: (
                    <div>
                        <p>The provided Audience and Landing URL have been recently used in another notification:</p>
                        <div>
                            <div>
                                <span>
                                    <b>Notification:</b>
                                    <ul>
                                        <li>
                                            <a href={notificationRoute} target="_blank" rel="noreferrer noopener">
                                                #{duplicate.id}: {duplicate.template.channels.web.title}
                                            </a>
                                        </li>
                                    </ul>
                                </span>
                            </div>
                            <div>
                                <span>
                                    <b>Duplicate Audiences:</b>
                                    <ul>{duplicateSegments}</ul>
                                </span>
                            </div>
                        </div>
                        Please confirm that you want to deliver this possible duplicate notification to the above
                        audiences.
                        {isDraft && (
                            <div>
                                <br></br>
                                This notification will no longer be shown in the Notification Drafts tab.
                            </div>
                        )}
                    </div>
                ),
                okText: `Yes, I'm Sure`,
                cancelText: 'Cancel',
                onOk: () => res(true),
                onCancel: () => res(false),
            })
        })
    } else {
        return true
    }
}

export const validateSubmit = async (
    builder: INotificationBuilderState,
    domain: DomainDto,
    flags: FlagList,
    isPreviewSend?: boolean,
    isDraft?: boolean,
) => {
    notification.destroy()

    const isCampaignLevel = builder.level === NotificationBuilderLevel.CAMPAIGN

    const testValidation = validateTest(builder)
    const audienceValidation = validateAudience(builder)
    const contentValidation = validateSelectedVariantContent(builder)
    const deliveryValidation = await validateSelectedVariantDelivery(builder, domain)
    const distValidation = { ok: true, errors: [] }
    let valid =
        testValidation.ok && audienceValidation.ok && contentValidation.ok && deliveryValidation.ok && distValidation.ok

    if (!valid) {
        simpleNotification(
            'error',
            <div>
                {!testValidation.ok && (
                    <p>
                        <b>Test Configuration</b>
                        <br />
                        {renderValidationErrors(testValidation)}
                    </p>
                )}
                {!audienceValidation.ok && (
                    <p>
                        <b>Audience</b>
                        <br />
                        {renderValidationErrors(audienceValidation)}
                    </p>
                )}
                {!contentValidation.ok && (
                    <p>
                        <b>Content</b>
                        <br />
                        {renderValidationErrors(contentValidation)}
                    </p>
                )}
                {!deliveryValidation.ok && (
                    <p>
                        <b>Delivery</b>
                        <br />
                        {renderValidationErrors(deliveryValidation)}
                    </p>
                )}
                {!distValidation.ok && (
                    <p>
                        <b>Audience Distribution</b>
                        <br />
                        {renderValidationErrors(distValidation)}
                    </p>
                )}
            </div>,
        )
    }

    let confirmed = true
    if (valid && !isCampaignLevel && !isPreviewSend) {
        confirmed = await confirmValidatedSubmit(builder, domain, flags, isDraft)
    }

    return valid && confirmed
}

export const validateSaveDraft = async (builder: INotificationBuilderState) => {
    notification.destroy()

    const contentValidation = validateSelectedVariantDraftContent(builder)
    const valid = contentValidation.ok

    if (!valid) {
        simpleNotification(
            'error',
            <div>
                {!contentValidation.ok && (
                    <p>
                        <b>Content</b>
                        <br />
                        {renderValidationErrors(contentValidation)}
                    </p>
                )}
            </div>,
        )
    }

    return valid
}

const confirmValidatedSubmit = async (
    builder: INotificationBuilderState,
    domain: DomainDto,
    flags: FlagList,
    isDraft?: boolean,
): Promise<boolean> => {
    const confirmImmediateSendFlag = flags.findActive(FEAT_IMM_NOTIF_CONFIRM)?.getKey()
    const confirmScheduledSendFlag = flags.findActive(FEAT_SCHED_NOTIF_CONFIRM)?.getKey()
    const duplicateCheckFlag = flags.findActive(FEAT_NOTIF_DUPLICATE_CHECKS)?.getKey()

    const mustConfirmImmediateSend = !!confirmImmediateSendFlag && domain.flags.includes(confirmImmediateSendFlag)
    const mustConfirmScheduledSend = !!confirmScheduledSendFlag && domain.flags.includes(confirmScheduledSendFlag)
    const checkForDuplicateNotifications = !!duplicateCheckFlag && domain.flags.includes(duplicateCheckFlag)

    const deliveryInProgress = builder.variants.some((v) => v.getDisplayMeta()?.delivering ?? false)
    const builds = buildSubmittedNotificationPackages(domain, builder, flags)
    const shell = builds[0]!
    const audience = shell.audience

    const isOrgLevel = builder.level === NotificationBuilderLevel.ORG
    const isDomainLevel = builder.level === NotificationBuilderLevel.DOMAIN

    let isAllSend = false
    let iDomains: DomainDto[] = []
    let iSegments: SegmentDto[] = []
    let xSegments: SegmentDto[] = []

    if (!!audience) {
        if (isOrgLevel) {
            const variant = builder.variants[0]
            isAllSend = variant.getAudience().getIsAll()

            const ids = isAllSend ? builder.availableDomains.map((d) => d.id) : audience.domainIds ?? []
            iDomains = builder.availableDomains.filter((d) => arrayContains(ids, d.id))
            const audIds = variant.getAudience().getSegmentIds() ?? []
            iSegments = builder.availableSegments.filter((s) => arrayContains(audIds, s.id))

            if (iSegments.length === 1 && iSegments[0].isDefault) isAllSend = true
            // reset to all send when all domains are mnaually targeted
            if (!isAllSend && iDomains.length === builder.availableDomains.length) {
                isAllSend = true
            }
        } else if (isDomainLevel) {
            if ('segmentIds' in audience) {
                const ids = audience.segmentIds
                iSegments = builder.availableSegments.filter((s) => arrayContains(ids, s.id))

                if (iSegments.length === 1 && iSegments[0].isDefault) {
                    isAllSend = true
                }
            }

            if ('excludedSegmentIds' in audience) {
                const ids = audience.excludedSegmentIds
                xSegments = builder.availableSegments.filter((s) => arrayContains(ids, s.id))
            }
        }
    }

    const audienceDisplay = isOrgLevel ? (
        <div>
            <br />

            <label>Domains:</label>
            <ul>{isAllSend ? <li>All Domains</li> : iDomains.map((d) => <li key={d.id}>{d.displayName}</li>)}</ul>

            <label>Audience:</label>
            <ul>
                {isAllSend || !iSegments.length ? (
                    <li>All Subscribers</li>
                ) : (
                    iSegments.map((s) => <li key={s.id}>{s.name}</li>)
                )}
            </ul>
        </div>
    ) : (
        <div>
            <br />

            <label>Targeted Segments:</label>
            <ul>{isAllSend ? <li>All Subscribers</li> : iSegments.map((s) => <li key={s.id}>{s.name}</li>)}</ul>

            {xSegments.length > 0 && (
                <>
                    <label>Excluded Segments:</label>
                    <ul>
                        {xSegments.map((s) => (
                            <li key={s.id}>{s.name}</li>
                        ))}
                    </ul>
                </>
            )}
        </div>
    )

    if (checkForDuplicateNotifications && isDomainLevel) {
        const duplicate = await checkforDuplicateNotifs(builder, domain, isDraft)
        if (!duplicate) return false
    }

    return new Promise((res) => {
        const isSTZ = shell.deliverySpec.window === NotificationDeliveryWindow.TIMEZONE
        const draftRemovalText = 'This notification will no longer be shown in the Notification Drafts tab.'

        if (isSTZ && deliveryInProgress) {
            Modal.confirm({
                title: 'Confirm Update',
                content: (
                    <div>
                        Changes made to this notification will take effect for subscribers in time zones that have not
                        yet began delivery. Are you sure you want to make these changes?
                    </div>
                ),
                okText: `Yes, I'm Sure`,
                cancelText: 'Cancel',
                onOk: () => res(true),
                onCancel: () => res(false),
            })
        } else if (
            shell.deliverySpec.type === NotificationDeliveryType.IMMEDIATE &&
            (mustConfirmImmediateSend || isDraft)
        ) {
            Modal.confirm({
                title: 'Confirm Immediate Delivery',
                content: (
                    <div>
                        Are you sure you want to send this notification <b>immediately</b> to{' '}
                        {numberWithCommas(builder.reachEstimate ?? 0)} subscribers?
                        {audienceDisplay}
                        {isDraft && <div>{draftRemovalText}</div>}
                    </div>
                ),
                okText: 'Yes, Send Immediately',
                cancelText: 'Cancel',
                onOk: () => res(true),
                onCancel: () => res(false),
            })
        } else if (
            shell.deliverySpec.type === NotificationDeliveryType.SCHEDULED &&
            (mustConfirmScheduledSend || isDraft)
        ) {
            const scheduledDateTime = moment
                .tz(shell.deliverySpec.sendDateUtc, shell.deliverySpec.timezone)
                .format(isSTZ ? `${BASE_TIME_FORMAT_WITHOUT_TZ} [STZ]` : BASE_TIME_FORMAT)

            Modal.confirm({
                title: 'Confirm Scheduled Delivery',
                content: (
                    <div>
                        Are you sure you want to schedule this notification to be sent to{' '}
                        {numberWithCommas(builder.reachEstimate || 0)} subscribers
                        {isOrgLevel ? '?' : ` at ${scheduledDateTime}?`}
                        {audienceDisplay}
                        {isDraft && <div>{draftRemovalText}</div>}
                    </div>
                ),
                okText: 'Yes, Schedule Delivery',
                cancelText: 'Cancel',
                onOk: () => res(true),
                onCancel: () => res(false),
            })
        } else {
            res(true)
        }
    })
}

export const getNormalizedDist = (parts: number, total: number = 100): number[] => {
    const basePct = total / parts
    const rawDist = new Array(parts).fill(Math.floor(basePct))
    if (isFloat(basePct)) {
        rawDist[rawDist.length - 1] = Math.ceil(basePct)
    }

    return Array.from(
        rawDist.sort((a, b) => a - b),
        (_, i) => i,
    )
        .sort((a, b) => (b % 2) - (a % 2) || (a % 2 ? a - b : b - a))
        .map((i) => rawDist[i])
}

export const resolveNotificationsProp = async (
    domain: DomainDto,
    notifications: PromiseableProp<NotificationDto[] | undefined>,
    builder: INotificationBuilderState,
    defaultSelectedId?: number,
): Promise<Partial<INotificationBuilderState>> => {
    const initialUpdate: Partial<INotificationBuilderState> = {
        initialNotificationStateLoaded: true,
        test: builder.test,
        variants: builder.variants,
    }

    if (!!notifications) {
        let loadedNotifications = await resolvePromiseableProp(notifications)
        // Ensure no null|undefined values
        loadedNotifications = loadedNotifications?.filter((n) => !!n)

        initialUpdate.initialNotificationState = loadedNotifications

        if (Array.isArray(loadedNotifications) && loadedNotifications.length > 0) {
            initialUpdate.variants = []

            loadedNotifications.forEach((notif, idx) => {
                if (!initialUpdate.test && notif.abTest?.id) {
                    initialUpdate.test = NotificationTestConfigurationModel.build(notif.abTest)
                }

                const variant = parseLoadedVariant(notif)
                initialUpdate.channels = variant.getChannels()
                const content = variant.getContent()

                if (notif.defaultTemplate.overrideDefaultActions === false) {
                    content.getDefaultContent().setActions(undefined)
                }

                /**
                 * Undefined media initialization edge-cases
                 *
                 * campaign node builders are dumb by design
                 *  and not domain aware - domain backfill logic
                 *  is relegated to the notification load/parsing
                 *  utilities.
                 */

                // ensure icon settings are properly initialized
                // cannot test against null as it is a valid value
                if (
                    notif.defaultTemplate.isUsingDomainDefaultIcon === undefined &&
                    notif.defaultTemplate.iconUrl === undefined
                ) {
                    // if campaign audience default icon url is set, it is a campaign segment override
                    if (builder.campaign?.configuration?.audience?.default_icon_url) {
                        content.getDefaultContent().setIconUrl(builder.campaign.configuration.audience.default_icon_url)
                    } else if (domain.defaultIconUrl) {
                        content.getDefaultContent().setIsUsingDomainDefaultIcon(true)
                        content.getDefaultContent().setIconUrl(domain.defaultIconUrl)
                    }
                }

                // ensure badge settings are properly initialized
                // cannot test against null as it is a valid value
                if (
                    notif.defaultTemplate.isUsingDomainDefaultBadge === undefined &&
                    notif.defaultTemplate.badgeUrl === undefined &&
                    !!domain.defaultBadgeUrl
                ) {
                    content.getDefaultContent().setIsUsingDomainDefaultBadge(true)
                    content.getDefaultContent().setBadgeUrl(domain.defaultBadgeUrl)
                }

                // Parse loaded ttl settings
                const expOpts = variant.getContent().getDefaultContent().getExperienceOptions()
                const ttlSet = getVariantTtlConfig(domain, expOpts)
                expOpts.setTtlSeconds(ttlSet.seconds)
                expOpts.setTtlMetric(ttlSet.metric)

                initialUpdate.variants!.push(variant)

                if (notif.id?.toString() === defaultSelectedId?.toString()) {
                    initialUpdate.selectedVariantIdx = idx
                }

                // set editable state
                if (builder.mode === 'edit') {
                    const isImmFixed =
                        notif.deliverySpec.type === NotificationDeliveryType.IMMEDIATE &&
                        notif.deliverySpec.window === NotificationDeliveryWindow.STANDARD
                    const isDraft = notif.status === StatusType.DRAFT.name
                    const isStarted = notif.status === StatusType.DELIVERING.name
                    const isCompleted =
                        notif.status === StatusType.COMPLETED.name ||
                        notif.status === StatusType.COMPLETED_WITH_FAILURES.name
                    const isFailed = notif.status === StatusType.FAILED.name
                    const isCancelled =
                        notif.status === StatusType.CANCELLED.name || notif.status === StatusType.CANCELLING.name

                    const editable = !isFailed && !isCancelled && !isCompleted && !(isImmFixed && isStarted)

                    variant.setDisplayMeta({
                        notEditable: !editable,
                        delivering: isStarted,
                        isDraft,
                    })

                    if (builder.type === 'template') {
                        initialUpdate.details = {
                            name: notif.template.name,
                            description: notif.template.description,
                        }
                    }
                } else {
                    const isTemplate = builder.type === 'template'
                    if (isTemplate) {
                        variant.setDisplayMeta({ isTemplate })
                    }
                }
            })

            if (!!initialUpdate.test) {
                const test = initialUpdate.test

                // Update loaded distribution map
                test.setDistributionMap(initialUpdate.variants.map((v) => v.getWeight()))

                // Handle legacy Content & Delivery
                const deliverySpecs = initialUpdate.variants.map((v) => v.getDelivery().serialize())
                const allSpecsMatch = deliverySpecs.every((v, i, arr) => deepEqual(v, arr[0]))
                const isLegacyAllType =
                    !loadedNotifications[0].abTest?.testTypes && isSingleDelivery(test.getTestTypes()) && !allSpecsMatch
                if (isLegacyAllType) {
                    test.setTestTypes(['content', 'delivery'])
                }
            }
        }
    }

    return initialUpdate
}

export const resolveTemplatesProp = async (
    domain: DomainDto,
    builder: INotificationBuilderState,
    defaultSelectedTemplateId: number | undefined,
) => {
    const initialUpdate: Partial<INotificationBuilderState> = {
        initialTemplatesStateLoaded: true,
        availableTemplates: [],
        selectedTemplate: defaultSelectedTemplateId,
        usingTemplate: defaultSelectedTemplateId !== undefined,
    }

    const templateService = new NotificationTemplateService()
    const { data: templates } = await templateService.fetchNamedNotificationTemplatesByDomainId(domain.id, {
        query: { include_actions: true, pagination: 0 },
    })
    initialUpdate.availableTemplates = templates

    if (initialUpdate.selectedTemplate) {
        const currTemplate = templates.find((t) => t.id === defaultSelectedTemplateId)
        initialUpdate.details = {
            name: currTemplate?.name,
            description: currTemplate?.description,
        }
    }

    return initialUpdate
}

export const canCreateSendToAll = (domain: DomainDto, appState: AppState): Boolean => {
    if (!domain) {
        return false
    }

    const domainFlags = [...(domain.flags ?? []), ...(domain.accountFlags ?? [])]
    const hasRestrictAllSubsFlag = domainFlags.includes(FEAT_RESTRICT_ALL_SUB_NOTIFS)
    const userIsAdmin = appState.abilityStore.can(AbilityAction.UPDATE, appState.currentDomain!)

    return userIsAdmin || !hasRestrictAllSubsFlag
}

export const buildPreviewOptions = (channels: DeliveryChannel[]): IBetterSelectOption[] => {
    const optSet = new Set<IBetterSelectOption>([])

    for (const channel of channels) {
        if (channel === DeliveryChannel.WEB) {
            optSet.add({
                value: Platform.ANDROID,
                label: 'Android (Web)',
            })

            optSet.add({
                value: Platform.WINDOWS,
                label: 'Web / Windows',
            })

            optSet.add({
                value: Platform.MAC_OS,
                label: 'MacOS',
            })
        } else if (channel === DeliveryChannel.NATIVE_ANDROID) {
            optSet.add({
                value: Platform.NATIVE_ANDROID,
                label: 'Android (Native)',
            })
        } else if (channel === DeliveryChannel.NATIVE_IOS) {
            optSet.add({
                value: Platform.IOS,
                label: 'Apple / iOS',
            })
        }
    }

    return [...optSet.values()].sort((a, b) => (a.label! > b.label! ? -1 : 1))
}

export const buildSubmittedNotificationPackages = (
    domain: DomainDto,
    builder: INotificationBuilderState,
    flags: FlagList,
    type: NotificationBuilderSubmitType = NotificationBuilderSubmitType.STANDARD,
    additionalMeta?: any,
    initialCreate?: boolean,
): any[] => {
    const builds: any[] = []
    const test = builder.test
    const isPreviewSend = type !== NotificationBuilderSubmitType.STANDARD
    const autoKwFlag = flags.findActive(FEAT_AUTO_KW_DISCOVERY)?.getKey()
    const hasAutoKwDiscoveryFlag = autoKwFlag && domain.flags?.includes(autoKwFlag)

    const isOrgLevel = builder.level === NotificationBuilderLevel.ORG
    const isDomainLevel = builder.level === NotificationBuilderLevel.DOMAIN
    const previousHasNativeAndroidChannel =
        builder.mode === 'edit' &&
        builder.initialNotificationState.some((notif) => notif.channels.includes(DeliveryChannel.NATIVE_ANDROID))
    const previousHasNativeIosChannel =
        builder.mode === 'edit' &&
        builder.initialNotificationState.some((notif) => notif.channels.includes(DeliveryChannel.NATIVE_IOS))
    const previousHasWebChannel =
        builder.mode === 'edit' &&
        builder.initialNotificationState.some((notif) => notif.channels.includes(DeliveryChannel.WEB))

    builder.variants.forEach((v, vidx) => {
        const build: any = {
            source: NotificationSource.MANUAL,
            meta: {
                origin_ui: 'new-notif-flow',
                ...(additionalMeta ?? {}),
            },
        }

        if (isDomainLevel) {
            build.domainId = domain.id
        }

        if (isPreviewSend) {
            build.source = NotificationSource.PREVIEW
            build.meta.origin_domain_id = domain.id
            build.meta.preview_type =
                type === NotificationBuilderSubmitType.PERSONAL_PREVIEW ? 'personal-preview' : 'team-preview'
        } else if (/^[0-9]+$/.test(v.getId())) {
            build.id = v.getId()
        }

        const audience = v.getAudience()
        const content = v.getContent()
        const defaultContent = content.getDefaultContent()
        const webContent = content.getWebContent()
        const nativeIosContent = content.getNativeIosContent()
        const nativeAndroidContent = content.getNativeAndroidContent()
        const exp = content.getDefaultContent().getExperienceOptions()
        const delivery = v.getDelivery()

        if (isOrgLevel && (audience.getIsAll() || (audience.getDomainIds()?.length ?? 0) > 1)) {
            build.meta.is_multi_domain = true
        }

        build.channels = builder.channels

        if (!isPreviewSend && test) {
            build.abTest = {
                id: test.getId(),
                name: test.getName(),
                testTypes: test.getTestTypes(),
                deliveryType: test.getDistributionType(), // API expects deliveryType - UI uses DistribtuionType
                ctrThreshold: test.getCtrThreshold() ?? null,
                weight: test.getDistributionMap()?.[vidx],
            }

            if (test.getDistributionType() === 'sample' && !!test.getEvaluationSchedule()) {
                const schedule = test.getEvaluationSchedule()!
                const scheduleBuild: any = {
                    type: schedule.getType(),
                }

                if (schedule.getType() === NdfTestScheduleType.ELAPSED) {
                    scheduleBuild.$elapsed_metric = schedule.getElapsedMetric() ?? 'hour'
                    scheduleBuild.elapsed_seconds = schedule.getElapsedSeconds()
                } else {
                    scheduleBuild.$evaluation_date_timezone = schedule.getEvaluationDateTimeZone() ?? domain.timezone
                    scheduleBuild.evaluation_date_utc = schedule.getEvaluationDateUtc()
                }

                build.abTest.evaluationSchedule = scheduleBuild
            }
        }

        // Audience
        if (type === NotificationBuilderSubmitType.PERSONAL_PREVIEW) {
            build.audience = { personalPreview: true }
        } else if (type === NotificationBuilderSubmitType.TEAM_PREVIEW) {
            build.audience = { teamPreview: true }
        } else if (isOrgLevel) {
            build.audience = {
                domainIds: audience.getIsAll() ? builder.availableDomains.map((d) => d.id) : audience.getDomainIds(),
            }

            if (audience.getSegmentIds()?.length) {
                build.audience.segmentIds = audience.getSegmentIds()
            }
        } else {
            if (audience.getIsAll()) {
                build.audience = {
                    all_subscribers: true,
                }
            } else {
                build.audience = {
                    segmentIds: audience.getSegmentIds(),
                    excludedSegmentIds: audience.getExcludedSegmentIds(),
                }
            }
        }

        // Delivery
        if (builder.type === 'notification') {
            if (isPreviewSend) {
                build.deliverySpec = {
                    type: NotificationDeliveryType.IMMEDIATE,
                    window: NotificationDeliveryWindow.STANDARD,
                }
            } else {
                build.deliverySpec = {
                    type: delivery.getType(),
                    window: delivery.getWindow(),
                    sendDateUtc: delivery.getSendDate(),
                    timezone: delivery.getTimeZone(),
                    ttlSeconds: exp.getTtlSeconds(),
                    ttlMetric: exp.getTtlMetric(),
                }
            }
        }

        let actions: any[] | undefined = defaultContent.getActions()?.map((action) => ({
            // model validation now expects string as data id is BigInt / string - update when model is fixed
            id: action?.getId(),
            ordinal: action.getOrdinal(),
            type: action.getType(),
            label: action.getLabel(),
            landingUrl: action.getLandingUrl(),
            usePrimaryLandingUrl: action.getUsePrimaryLandingUrl(),
        }))

        if (defaultContent.getCustomActionsEnabled() && (actions?.length ?? 0) === 0) {
            actions = getDefaultNotificationActions(domain)
        }

        // build default template
        const template: any = {
            channels: {},
        }

        template.channels.default = {
            channels: build.channels,
            title: defaultContent.getTitle(),
            body: defaultContent.getBody(),
            landingUrl: defaultContent.getLandingUrl() === '' && initialCreate ? null : defaultContent.getLandingUrl(),
            imageUrl: defaultContent.getImageUrl(),
            iconUrl: defaultContent.getIconUrl(),
            contentWebhookUrl: defaultContent.getContentWebhookUrl(),
            overrideFallbackTemplate: defaultContent.getOverrideFallbackTemplate(),
            badgeUrl: defaultContent.getBadgeUrl(),
            isUsingDomainDefaultIcon: defaultContent.getIsUsingDomainDefaultIcon(),
            isUsingDomainDefaultBadge: defaultContent.getIsUsingDomainDefaultBadge(),
            overrideDefaultActions: defaultContent.getCustomActionsEnabled(),
            autoSuggestedFieldsResults: defaultContent.getAutoSuggestedFieldsResults(),
            actions: defaultContent.getCustomActionsEnabled() ? actions : undefined,
            isSilent: exp.getIsSilent(),
            requireInteraction: exp.getRequireInteraction(),
        }

        // build ios content
        if (build.channels.includes(DeliveryChannel.NATIVE_IOS)) {
            template.channels.nativeIos = {
                landingUrl: defaultContent.getNativeAltUrl() ?? null,
                category: nativeIosContent.getCategory() ?? null,
                subtitle: nativeIosContent.getSubtitle() ?? null,
                sound: nativeIosContent.getSound() ?? null,
                interruptionLevel: nativeIosContent.getInterruptionLevel() ?? null,
                relevanceScore: nativeIosContent.getRelevance() ?? null,
                targetContentId: nativeIosContent.getTargetContentId() ?? null,
                title: nativeIosContent.getTitle() ?? null,
                body: nativeIosContent.getBody() ?? null,
                iconUrl: nativeIosContent.getIconUrl() ?? null,
                imageUrl: nativeIosContent.getImageUrl() ?? null,
            }

            const badgeBehavior = nativeIosContent.getBadgeBehavior() ?? null
            if (!badgeBehavior || badgeBehavior.getAction() === IosBadgeBehaviorAction.DO_NOTHING) {
                template.channels.nativeIos.badgeBehavior = null
            } else {
                template.channels.nativeIos.badgeBehavior = badgeBehavior
            }
        } else if (previousHasNativeIosChannel) {
            template.channels.nativeIos = null
        }

        // build native android content
        if (build.channels.includes(DeliveryChannel.NATIVE_ANDROID)) {
            template.channels.nativeAndroid = {
                landingUrl: defaultContent.getNativeAltUrl() ?? null,
                title: nativeAndroidContent.getTitle() ?? null,
                body: nativeAndroidContent.getBody() ?? null,
                iconUrl: nativeAndroidContent.getIconUrl() ?? null,
                imageUrl: nativeAndroidContent.getImageUrl() ?? null,
                channelId: nativeAndroidContent.getChannel() ?? null,
                // actions from UI are built via default -- eventually will decouple
                actions: defaultContent.getCustomActionsEnabled() ? actions : undefined,
                displayMeta: {
                    override_default_actions: defaultContent.getCustomActionsEnabled(),
                },
            }
        } else if (previousHasNativeAndroidChannel) {
            template.channels.nativeAndroid = null
        }

        // build web content
        if (build.channels.includes(DeliveryChannel.WEB)) {
            template.channels.web = {
                // currently no separation between default and current
                landingUrl: defaultContent.getLandingUrl() || null,
                title: webContent.getTitle() || null,
                body: webContent.getBody()?.trim() || null,
                imageUrl: webContent.getImageUrl()?.trim() ?? null,
                iconUrl: webContent.getIconUrl()?.trim() ?? null,
                // actions from UI are built via default -- eventually will decouple
                actions: defaultContent.getCustomActionsEnabled() ? actions : undefined,
                displayMeta: {
                    override_default_actions: defaultContent.getCustomActionsEnabled(),
                },
            }
        } else if (previousHasWebChannel) {
            template.channels.web = null
        }

        build.template = {
            channels: template.channels,
            keywords: defaultContent.getKeywords(),
            auto_keyword_discovery: hasAutoKwDiscoveryFlag ? true : undefined,
        }

        if (builder.selectedTemplate) {
            build.template.source_template_id = builder.selectedTemplate
        }

        if (builder.type === 'template') {
            build.name = builder.details.name
            build.description = builder.details.description === '' ? null : builder.details.description

            build.ttlSpec = {
                ttlSeconds: exp.getTtlSeconds(),
                ttlMetric: exp.getTtlMetric(),
            }
        }

        builds.push(stripUndefined(build))
    })

    return builds
}
